export class Auth {
    id: string = null;
    email: string = null;
    firstName: string = null;
    lastName: string = null;
    domain: string = null;
    class: string = null;
    saleLease: string = null;
    area: string = null;
    type: string[] = null;
    max_price: number = null;
    min_bed: number = null;
    min_wash: number = null;
    role: string = 'User';       // 'User', 'Author', 'Admin'
    registered: Date = null;
    status: string = null;     // N)ew, A)ctive, B)anned
}
