import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Cart } from './cart';
// import { MessagesService } from '../messages/messages.service';
import { Observable, of, Subject, observable, throwError } from 'rxjs';
import { tap, catchError, map, exhaustMap, take } from 'rxjs/operators';
// import { LogMessageService } from '../message/log-message/log-message.service'
// import { AlertMessageService } from '../message/alert-message/alert-message.service';
// import { LogMessageService } from '../message/log-message.service';
// import { AlertMessageService } from '../message/alert-message.service';
// import { LogMessageService } from 'projects/log-message/src/public-api';
// import { AlertMessageService } from 'projects/alert-message/src/public-api';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-type': 'application/json' }
  )
};

@Injectable({
  providedIn: 'root'
})

export class CartService {

  constructor(
    private http: HttpClient,
    // private logMessage: LogMessageService,
    // private alertMessage: AlertMessageService,
    // private myService: MessagesService,
  ) { }
  message = new Subject<string>();


  cart$ : Observable<Cart>;
  carts$: Observable<Cart[]>;
  cart : Cart;
  // firebaseID : string;

  private cartUrl :string;
  error = new Subject<string>();
  private firebaseURL = 'https://visage-82084.firebaseio.com';

  getDomainName() {
    let hostName = window.location.hostname;
    // console.log(`hostname=${this.hostName}`);
    let domainName = hostName == '192.168.1.38' ? 'visagehome.com' : hostName.replace(/www./,'');
    this.cartUrl = `https://backend.visagehome.com/php/cart.php`;
    // console.log(`vowUrl = ${this.vowUrl}`);
  }

  getCarts(email: string): Observable<Cart[]> {
    this.getDomainName();

    let url = `${this.cartUrl}?t=cart`;
    if (email) url += `&email=${email}`;
    // console.log(url);

    return this.http.get<Cart[]>(url)
      .pipe(

        tap(_ => {
          this.log(`fetched carts email=${email}`);
          // console.log(_);
        }) ,
        catchError(this.handleError<Cart[]>('getCarts',[]))
      );
  }

  getCart(cart_id: number): Observable<Cart> {
    this.getDomainName();
    const url = `${this.cartUrl}?id=${cart_id}`;
    console.log(url);
    // const params = new HttpParams()
    //   .set('ml_num', `${ml_num}`);
    return this.http.get<Cart>(url)
    .pipe(
      tap(_ => this.log(`fetched cart id=${cart_id}`)),
      catchError(this.handleError<Cart>(`getCart id=${cart_id}`))
    );
  }

  addCart (cart: Cart): Observable<any> {
    // console.log("At addCart mls="+cart.ml_num);
    this.getDomainName();
    // console.log(this.cartUrl);

    return this.http.post<Cart>(this.cartUrl, cart, httpOptions)
    .pipe(
      tap(_ => {
        // console.log(_);
        this.log(`added cart ${cart.email} ${cart.ml_num}`);
      }),
      // catchError(this.handleError<any>('addCart', cart))
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error adding cart ${cart.email} ${cart.ml_num}`)
        return throwError(err);
      })
    );
  }

  /** DELETE: delete the cart from the server */
  deleteCart (mls: string, email: string): Observable<any> {
    // console.log(`At deleteCart mls=${mls} email=${email}`);
    // const id = typeof cart === 'number' ? cart : cart.cart_id;
    this.getDomainName();
    const url = `${this.cartUrl}?mls=${mls}&email=${email}`;
    // console.log(url);
    return this.http.delete<Cart>(url, httpOptions)
    .pipe(
      tap(_ => this.log(`deleted cart mls=${mls} email=${email}`)),
      // catchError(this.handleError<any>('deleteCart'))
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error delete cart ${mls} ${email}`)
        return throwError(err);
      })
    );
  }


  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */

  private log(message: string) {
    // this.logMessage.add(`CartService: ${message}`);
  }

  private alert(type: string, message: string) {
    // this.alertMessage.add(type, `CartService: ${message}`);

  }

}
