import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Cms } from './cms';
// import { CmsCat } from './cmscat';
// import { MessagesService } from '../messages/messages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap, switchMap, exhaustMap } from 'rxjs/operators';
// import { LogMessageService } from '../message/log-message.service';
// import { AlertMessageService } from '../message/alert-message.service';
// import { LogMessageService } from '../message/log-message/log-message.service';
// import { AlertMessageService } from '../message/alert-message/alert-message.service';
import { CmsEditPage } from '../setting/cms/cms-edit/cms-edit.page';
// import { AlertMessageService } from 'projects/alert-message/src/public-api';
// import { LogMessageService } from 'projects/log-message/src/public-api';
// import { Codes } from './codes';
// import { WP_Posts } from './wp_posts';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-type': 'application/json' }
  )
};

export interface cmsId {
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class CmsService {

  constructor(
    private http: HttpClient,
    // private logMessage: LogMessageService,
    // private alertMessage: AlertMessageService,
    // private myService: MessagesService,
  ) { }

  Category: string;
  message = new Subject<string>();

  private cmsUrl: string;
  error = new Subject<string>();
  private firebaseURL = 'https://visage-82084.firebaseio.com';
  // private postUrl = 'https://admin.visagehome.com/php/getpost.php';

  fetchCmss(domain: string): Observable<Cms[]> {
    // console.log(`fetchCmss: cat=${cat} count=${count} status=${status}`)
    let dom = domain.replace(/\./g, '-');
    let url = `${this.firebaseURL}/cms/${dom}.json`;
    // url += `?orderBy="page"&equalTo="${page}"`;

    return this.http.get<Cms[]>(url).pipe(
      // tap(_ => this.log(`fetched cmss cat=${cat} count=${count} status=${status}`)),
      map((responseData: { [key: number]: Cms }) => {
        // console.log('line');
        console.log(responseData);
        let cmsArray: Cms[] = [];
        if (responseData == null) {
          let cms = this.initCms();
          cms.domain = domain;
          cms.page = 'home';
          cms.title = 'Home Page';
          this.http.put(`${this.firebaseURL}/cms/${dom}/${cms.page}.json`, cms)
            .subscribe((resp: Cms) => cmsArray.push(resp));
          cms.page = 'about';
          cms.title = 'About Us';
          this.http.put(`${this.firebaseURL}/cms/${dom}/${cms.page}.json`, cms)
            .subscribe((resp: Cms) => cmsArray.push(resp));
        } else {
          // let idx:number = 0;
          for (const key in responseData) {
            // console.log(`key=${key}`);
            if (responseData.hasOwnProperty(key) && responseData[key]) {
              cmsArray.push({ ...responseData[key] });
            }
          }
        }
        // console.log(cmsArray);
        // sort in desc date order
        cmsArray.sort((a, b) => (a.page > b.page) ? -1 : 1);
        // if (count) {
        //   cmsArray = cmsArray.slice(0, count);
        // }
        return cmsArray;
      }),
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error fetching cmss domain=${domain}`);
        return throwError(err);
      })
    )
  }

  fetchCms(domain: string, page: string): Observable<Cms> {
    // let searchParams = new HttpParams();
    // searchParams = searchParams
    //   .append('print', 'pretty')
    //   // .append('custom','key')
    //   ;

    domain = domain.replace(/\./g, '-');
    return this.http.get<Cms>(`${this.firebaseURL}/cms/${domain}/${page}.json`)
      .pipe(
        map(responseData => {
          // console.log(responseData);
          return responseData;
        }),
        catchError(err => {
          // Send to analytics server
          console.log(err);
          this.alert('Danger', `Error fetching cms domain=${domain} page=${page}`);
          return throwError(err);
        })
      )
  }

  initCms(): Cms {
    let cms = new Cms();
    return cms;
  }

  //////// Save methods //////////

  /** POST: add a new cms to the server */
  addCms(cms: Cms): Observable<any> {
    // delete cms["submit"];

    console.log('in addcms');
    let domain = cms.domain.replace(/\./g, '-');
    // console.log(cms);
    // cms.status = 'D';  // draft
    // return this.http.post(`${this.firebaseURL}/cms/${cms.domain}/${cms.page}.json`,cms).pipe(
    return this.http.put(`${this.firebaseURL}/cms/${domain}/${cms.page}.json`, cms).pipe(
      map(resp => {
        console.log(resp);
        for (const key in resp) {
          let cmsId: string = resp[key];
        }
        return resp;
      }),
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error adding criteria ${cms.title}`)
        return throwError(err);
      })
    )
    // catchError(_ =>{})
  }

  /** DELETE: delete the cms from the server */
  deleteCms(cms: Cms): Observable<any> {
    let domain = cms.domain.replace(/\./g, '-');
    return this.http.delete<{ resp: any }>(
      `${this.firebaseURL}/cms/${domain}/${cms.page}.json`
    ).pipe(

      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error deleting cms ${cms.domain} ${cms.page}`)
        return throwError(err);
      })
    );
  }

  /** PUT: update the hero on the server */
  // updateCms(cms: Cms): Observable<any> {
  //   return this.http.put(this.cmsUrl, cms, httpOptions).pipe(
  //     tap(_ => this.log(`updated cms id=${cms.cms_id}`)),
  //     catchError(this.handleError<any>('updateCms'))
  //   );
  // }

  updateCms(cms: Cms): Observable<any> {
    let domain = cms.domain.replace(/\./g, '-');
    return this.http.put<{ resp: any }>(`${this.firebaseURL}/cms/${domain}/${cms.page}.json`, cms).pipe(
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error updating criteria ${cms.domain} ${cms.page}`)
        return throwError(err);
      })
    )
  }

  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
    // this.logMessage.add(`CmsService: ${message}`);
  }

  private alert(type: string, message: string) {
    // this.alertMessage.add(type, `CmsService: ${message}`);

  }

}
