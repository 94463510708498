export class Cms {
    id: string = null;
    domain: string = null;
    page: string = null;
    title: string = null;
    date: Date = null;
    type: string = null;
    author: string = null;
    content: string = null;
    keyword: string = null;
    description: string = null;
    status: string = null;
}

